// Colors
$blue: #25aae2;
$green: #8bc540;
$red: #dc5d42;
$gray-dark: #666666;
$gray: #ababab;
$yellow: #f2b53a;
$seller-red: #ec3a3a;
$seller-blue: #d9edf7;
$alert-bg: #fbe9e5;
$alert-red: #de5c42;

// Base
$body-bg: #f8f9fa; // $gray-100

// Fonts
$font-family-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
