@import '../styles/variables.scss';

.main {
    background-color: rgba($green, 0.4);
    color: $green;
}

.main:hover {
    color: darken($green, 10%);
    color: $white;
}

.badge {
    background-color: $green;
}
