.text-accent {
    color: var(--primary) !important;
}

.bg-accent {
    background-color: var(--primary) !important;
}

.border-accent {
    border-color: var(--primary) !important;
}
