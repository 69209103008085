.cover {
    object-fit: cover;
}

.bg-cover {
    background-size: cover;
}

.modal-lg {
    max-width: 610px;
}

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: 2px 2px 10px 0px #0000003d;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}
