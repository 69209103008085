.main {
    opacity: 0.75;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main:hover {
    opacity: 1;
}

.logo {
    width: 60px;
    height: 20px;
}
