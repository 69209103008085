@import './bootstrap';
@import './accent';
@import './buttons';
@import './utilities';

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
}
