@import '../../styles/variables';

.main {
    width: 80px !important;
    cursor: pointer;
    transition: background-color 0.25s;
}

.mainSelected,
.main:hover,
.main:active {
    background-color: rgba($blue, 0.1) !important;
}
