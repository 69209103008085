@import '../../styles/variables';

.main {
    transition: background-color 0.3s;
    border-bottom: 1px solid $gray-300;
}

.main:last-child {
    border-bottom: none;
}

.mainHover {
    cursor: pointer;
}

.mainHover:hover {
    background-color: $gray-100;
}

.mainSelected {
    background-color: $gray-100;
}

.row {
    align-items: stretch;

    @include media-breakpoint-up(md) {
        align-items: center;
    }
}
