@import '../../styles/variables';

.main {
    width: 80px;
    min-width: 80px;

    @include media-breakpoint-up(md) {
        width: 110px;
        min-width: 110px;
    }

    @include media-breakpoint-up(xl) {
        width: 140px;
        min-width: 140px;
    }
}
