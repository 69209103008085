@import '../../styles/variables';

.line {
    background-color: $gray-300;
    height: 1px;
    flex: 1;
}

.lineCurrent {
    background-image: linear-gradient(to right, $blue, rgba(0, 0, 0, 0)) !important;
    background-image: linear-gradient(to right, var(--primary), rgba(0, 0, 0, 0)) !important;
}
