@import '../../styles/variables';

.button {
    display: inline-block;
    text-decoration: underline;
    font-weight: bold;
    color: $gray-900;
    cursor: pointer;
}

.button:hover {
    color: $gray-700;
}
