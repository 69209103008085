@import '../styles/variables';

.info {
    margin-bottom: 20px;
}

.content {
    width: 610px;
}

.alert {
    background-color: $seller-red;
}

.alert:hover {
    background-color: $seller-red;
    opacity: 0.8;
}

.error {
    background-color: $alert-bg;
    border-color: transparent;
    color: $alert-red;
}

.notice {
    background-color: $seller-blue;
}
